$theme-colors: (
  'primary': #d73231,
  'warning': rgba(72, 72, 72, 0.56),
  'info': rgba(255, 255, 255, 0.24),
  'danger': #ffab2e,
);

$color-gradient-base: rgb(1, 1, 23);
$color-dark: #2f3542;
$color-primary: map-get($theme-colors, 'primary');
$color-info: map-get($theme-colors, 'info');
$color-danger: map-get($theme-colors, 'danger');
$color-warning: map-get($theme-colors, 'warning');
$color-body-bg: #010118;
$color-body-color: white;
$color-border: rgba($color-body-color, 0.12);
$color-border-inverted: rgba(black, 0.12);
$color-dark-grey: rgba($color-body-color, 0.7);
$color-darker-grey: rgba(72, 72, 72, 0.24);
$color-image-gradient: rgba(1, 1, 24, 0);
$color-input-color: $color-body-color;
$color-input-bg: rgba(72, 72, 72, 0.48);
$color-input-focus-bg: rgba(72, 72, 72, 0.64);
$color-input-focus-border: rgba(255, 255, 255, 0.32);
$color-input-placeholder: $color-dark-grey;
$color-input-disabled-color: rgba(255, 255, 255, 0.4);
$color-photo-bg: rgba(72, 72, 72, 0.4);
$color-input-search-bg: rgba(255, 255, 255, 0.3);
$color-form-gradient-base: #0d1321;
$color-contact-bg: #1d1d2b;
$color-border-btn: rgba($color-gradient-base, 0.16);
$color-bg: #010117;
$color-gold: #deae3f;
$color-text-anchor-hover: #b42524;
$color-coming-soon: #525264;
$color-donate-popup-bg: rgba(47, 53, 66, 0.8);
$color-player-menu-bg: $color-donate-popup-bg;
$color-player-menu-selection-bg: rgba(white, 0.72);
$color-player-menu-selection-bg-hover: rgba(255, 255, 255, 0.08);
$color-body-color-darker: rgba($color-body-color, 0.56);

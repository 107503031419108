@import "../../styles/colors";
@import "../../styles/mixins";

.login {
  padding-top: 100px;
  padding-bottom: 40px;
  position: relative;

  @include desktop {
    padding-top: 130px;
    width: 370px;
    margin: 0 auto;
  }

  .loginWrapper {
    max-width: 540px;
    margin: 0 auto;
  }

  h1,
  p {
    text-align: center;
  }

  p {
    a {
      color: $color-primary;
      font-weight: bold;

      &:hover {
        color: $color-text-anchor-hover;
      }
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    background-color: $color-border;
    position: absolute;
    bottom: 0;
    left: -15px;
    right: -15px;

    @include desktop {
      display: none;
    }
  }
}

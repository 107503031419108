@mixin desktopFull() {
  @media (min-width: 1260px) {
    @content;
  }
}

@mixin mobileMenu() {
  @media (max-width: 1052px) {
    @content;
  }
}

@mixin desktopMenuIconsOnly() {
  @media (min-width: 1053px) and (max-width: 1204px) {
    @content;
  }
}

@mixin desktopMenu() {
  @media (min-width: 1053px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin popupWidth {
  @media (min-width: 806px) {
    @content;
  }
}

@mixin carouselMaxWidth() {
  @media (min-width: 720px) {
    @content;
  }
}

@mixin mobileLarge() {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin carouselMinHeight() {
  @media (max-height: 740px) {
    @content;
  }
}

@mixin carouselAbsoluteMinHeight() {
  @media (max-height: 550px) {
    @content;
  }
}

@mixin hoverSupported() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
